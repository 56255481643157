import React, { useState, useEffect } from 'react';
import {Route, MemoryRouter} from 'react-router-dom';
import './css/App.css';
import './css/CDM-App.css';
import CDMLogo from './img/cdm_logo.png';
import CRPLogo from './img/crp-logo.jpg';

import Menu from "./Pages/Menu";
import Calendar from "./Pages/Calendar";
import { default as CalendarDetail } from "./Pages/Calendar/Detail";
import LiveContent from "./Pages/LiveContent";

import useGlobalState from "./store/useGlobalState";
import GlobalStateContext from "./store/GlobalStateContext";

import Api from "./Utils/Api";
import EventSwitcher from "./EventSwitcher";
import RealtimeResults from "./Pages/RealtimeResults";
import Firebase from "./Utils/Firebase";
import Results from "./Pages/Results";
import ResultsDetail from "./Pages/ResultsDetail";
import TimeTable from "./Pages/TimeTable";

const App = () => {
    const {globalState, setGlobalState} = useGlobalState({
        data : {},

        showEventSwitcher : false,
        showStageSwitcher : false,
        currentEventId : null,

        showLiveContentBtn : false,
        showTimetableBtn : false,
        showResultsBtn : false,
    });
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // ... componentDidMount
        Api.fetchAppData().then((data) => {
            setGlobalState({
                data : data,
            });

            onAppDataReady();
        });

        return () => {
            Firebase.stopCheckingLiveContentAvailability();
            Firebase.stopCheckingRealtimeResultsAvailability();
            Firebase.Timetable.stopCheckingAvailability();
        }
    }, []);

    useEffect(() => {
        const data = globalState.data;

        if (undefined === data.currentEvents) {
            return;
        }

        const localState = {
            showLiveContentBtn : false,
            showResultsBtn : false,
            showTimetableBtn : false,
        };
        for (const eventId in data.currentEvents) {
            if (data.currentEvents.hasOwnProperty(eventId)) {
                const event = data.currentEvents[eventId];

                for (const stageId in event.stages) {
                    if (event.stages.hasOwnProperty(stageId)) {
                        Firebase.isLiveContentAvailable(eventId, stageId, (available) => {
                            if (true === available) {
                                setGlobalState({...localState, showLiveContentBtn: true});
                                localState.showLiveContentBtn = true;
                            }
                        });

                        Firebase.isRealtimeResultsAvailable(eventId, stageId, (available) => {
                            if (true === available) {
                                setGlobalState({...localState, showResultsBtn : true});
                                localState.showResultsBtn = true;
                            }
                        });

                        Firebase.Timetable.isAvailable(eventId, stageId, (available) => {
                            if (true === available) {
                                setGlobalState({...localState, showTimetableBtn : true});
                                localState.showTimetableBtn = true;
                            }
                        });
                    }
                }
            }
        }
    }, [globalState.data]);

    useEffect(() => {
        Firebase.stopCheckingLiveContentAvailability();
    }, [globalState.showLiveContentBtn]);

    useEffect(() => {
        Firebase.stopCheckingRealtimeResultsAvailability();
    }, [globalState.showResultsBtn]);

    useEffect(() => {
        Firebase.Timetable.stopCheckingAvailability();
    }, [globalState.showTimetableBtn]);


    const onAppDataReady = () => {
        setLoaded(true);

        if ("function" === typeof window.ready) {
            window.ready();
        }
    };

    if (loaded) {
        return <GlobalStateContext.Provider value={{globalState, setGlobalState}}>
            <div className="content">
                <MemoryRouter>
                    <Route exact path={'/'} component={Menu} />
                    <Route exact path={'/calendar'} component={Calendar} />
                    <Route exact path={'/calendar/event/:id'} component={CalendarDetail} />
                    <Route exact path={'/livecontent/:eventId/:stageId?'} component={LiveContent} />
                    <Route exact path={'/realtime-results/:eventId/:stageId'} component={RealtimeResults} />
                    <Route exact path={'/results/:eventId'} component={Results} />
                    <Route exact path={'/results/:eventId/:stageId/:roundId'} component={ResultsDetail} />
                    <Route exact path={'/timetable/:eventId/:stageId'} component={TimeTable} />

                    { true === globalState.showEventSwitcher ? <EventSwitcher /> : null }
                </MemoryRouter>
                <div className="crp-footer">
                    <a href="http://cz.crproduction.cz" target="_blank" rel="noopener noreferrer"><img src={CRPLogo} alt="CR Production"/></a>
                </div>
            </div>
        </GlobalStateContext.Provider>
    } else {
        return <div className="Loader">
            <img src={CDMLogo} alt="Czech Dance Masters"/>
        </div>
    }
};

export default App;
