import React, {useContext} from 'react';
import { withRouter } from 'react-router-dom';

import {default as TimeTableBtn} from '../Buttons/TimeTable';
import {default as ResultsBtn} from "../Buttons/RealtimeResults";
import GlobalStateContext from "../../store/GlobalStateContext";

const Item = (props) => {
    const { globalState, setGlobalState } = useContext(GlobalStateContext);

    const isRealtime = () => {
        if (null !== props.event && undefined !== props.event) {
            return undefined !== globalState.data.currentEvents[props.event.uuid];
        }

        return false;
    }

    return <li key={props.id} className="calendar__item">
        <TimeTableBtn event={props.event} visible={ props.timetable } />
        <button className="btn btn--calendar-event" onClick={() => { props.history.push(`/calendar/event/${props.id}`) }}>
            {props.title}
        </button>
        <ResultsBtn event={props.event} realtime={isRealtime()} visible={ props.results } />
    </li>
};

export default withRouter(Item);