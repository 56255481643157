import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import LiveContentBtn from "../../img/eye.svg";

import GlobalStateContext from "../../store/GlobalStateContext";

const LiveContent = (props) => {
    const { globalState, setGlobalState } = useContext(GlobalStateContext);
    const { data } = globalState;
    let pressTimer;

    const _onClick = () => {
        const eventInMemory = localStorage.getItem('currentEventId');
        const stageInMemory = localStorage.getItem('currentStageId');

        if (Object.keys(data.currentEvents).length === 1 || null !== eventInMemory) {
            let eventId = (null !== eventInMemory && undefined !== data.currentEvents[eventInMemory]) ? eventInMemory : Object.keys(data.currentEvents)[0];
            let stageId = null;

            if (null === stageInMemory) {
                stageId = Object.keys(data.currentEvents[eventId].stages)[0];
            } else {
                if ( undefined !== data.currentEvents[eventId].stages[stageInMemory] ) {
                    stageId = stageInMemory;
                }
            }

            props.history.push(`/livecontent/${eventId}/${stageId}`);
        } else {
            setGlobalState({
                showEventSwitcher : true,
                eventSwitcherMode : "livecontent",
            });
        }
    };

    const _onLongTouch = () => {
        if (Object.keys(globalState.data.currentEvents).length === 1) {
            _onClick();
        } else {
            setGlobalState({
                showEventSwitcher: true,
                eventSwitcherMode : "livecontent",
            });
        }
    };

    const _onTouchStart = () => {
        pressTimer = setTimeout(_onLongTouch, 500);

        return false;
    };

    const _onTouchEnd = () => {
        if (undefined !== pressTimer) {
            clearTimeout(pressTimer);
        }

        return false;
    };

    if (true === props.visible) {
        return <button className="btn btn--livecontent" onClick={_onClick} onTouchStart={_onTouchStart} onTouchEnd={_onTouchEnd} onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); e.cancelBubble = true; return false; }}>
            <img src={LiveContentBtn} alt="Živý přehled" />
        </button>
    } else {
        return <span className="btn-group-spacer" />
    }
};

export default withRouter(LiveContent);