import moment from "moment";

const format = (_date, _format) => {
    const date = moment(_date, moment.ISO_8601);

    return date.format(_format || "D. M. Y")
};

const formatFromTo = (_dateFrom, _dateTo) => {
    const dateFrom = moment(_dateFrom, moment.ISO_8601);
    const dateTo = undefined !== _dateTo ? moment(_dateTo, moment.ISO_8601) : null;

    // TODO : from to

    return null;
};

export default {
    format
};