import React, {useContext, useEffect, useState} from 'react';

import {default as BackBtn} from "../Components/Buttons/Back";
import DateFormater from "../Utils/DateFormater";
import Firebase from "../Utils/Firebase";
import Results from "../Components/Results";
import GlobalStateContext from "../store/GlobalStateContext";


const ResultsDetail = (props) => {
    const { globalState } = useContext(GlobalStateContext);
    const { eventId, stageId, roundId } = props.match.params;
    let currentEvent;
    if (undefined !== globalState.selectedEvent) {
        currentEvent = globalState.selectedEvent;
    } else {
        currentEvent = globalState.data.currentEvents[eventId];
    }
    const [results, setResults] = useState({});

    useEffect(() => {
        if ( "object" !== typeof currentEvent ) {
            props.history.goBack();

            return null;
        }

        Firebase.fetchResults(eventId, stageId, roundId)
            .then((data) => {
                setResults({...data});
            });
    }, []);

    return <div className="page--results-detail">
        <header>
            <BackBtn />

            <span id="date">{ DateFormater.format(currentEvent.dateStart) }</span>
            <h1>{currentEvent.title}</h1>
        </header>


        { Object.keys(results).length > 0 ? <Results results={results} /> : null }
    </div>;
};

export default ResultsDetail;