import React, {useContext, useEffect, useState} from 'react';
import {default as TimeTableComponent} from '../Components/TimeTable';

import 'firebase/database';

import Firebase from "../Utils/Firebase";
import DateFormater from "../Utils/DateFormater";
import {default as BackBtn} from "../Components/Buttons/Back";
import GlobalStateContext from "../store/GlobalStateContext";
import {default as RealtimeResultsBtn} from "../Components/Buttons/RealtimeResults";
import {default as LiveContentBtn} from "../Components/Buttons/LiveContent";

const usePublished = (eventId, stageId) => {
    const [timetable, setTimetable] = useState({});

    useEffect(() => {
        return Firebase.Timetable.listen(eventId, stageId, (data) => {
            setTimetable(data);
        });
    }, []);

    return timetable;
};

const TimeTable = (props) =>
{
    const { globalState } = useContext(GlobalStateContext);
    let currentEvent;
    if (undefined !== globalState.selectedEvent) {
        currentEvent = globalState.selectedEvent;
    } else {
        currentEvent = globalState.data.currentEvents[props.match.params.eventId];
    }
    const currentStage = currentEvent.stages[props.match.params.stageId];
    const heats = usePublished(props.match.params.eventId, props.match.params.stageId);

    return <div className="page--timetable">
        <header>
            <BackBtn />

            <span id="date">{ DateFormater.format(currentStage.date) }</span>
            <h1>{currentStage.title}</h1>
        </header>
        <div className="btn-group--vertical">
            <LiveContentBtn visible={globalState.showLiveContentBtn} />
            <RealtimeResultsBtn realtime={true} visible={globalState.showResultsBtn} />
        </div>

        <TimeTableComponent heats={heats} />
    </div>;
};

export default TimeTable;