import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Loader } from "@googlemaps/js-api-loader"
import App from './App';
import moment from 'moment';
import momentCz from 'moment/locale/cs';

// Sentry.init({dsn: "https://3d1044706d4f487cae1f63e2daa364c9@sentry.io/1504598"});

const loader = new Loader({
    apiKey: "AIzaSyAOqDMwBik0JIhBaHattt7qgHiqGXyfMm4",
    version: "weekly",
});

loader.load();

ReactDOM.render(<App />, document.getElementById('root'));