import React, {Component} from 'react';
import HeatHeat from "./HeatHeat";

class HeatRound extends Component
{
    constructor(props) {
        super(props);

        const date = new Date();
        date.setMinutes(date.getMinutes() - 2);

        this.state = {
            open: false,
            isNew : (props.received * 1000 > date.getTime())
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.received !== this.props.received) {
            const date = new Date();
            date.setMinutes(date.getMinutes() - 2);

            this.setState({
                isNew : (this.props.received * 1000 > date.getTime())
            });
        }
    }

    render() {
        const { heats } = this.props;

        return <div className={"heat__round-row" + (this.state.isNew ? ' heat__round-row--new' : '')}>
            <div className="heat__round-head">
                <span className="heat__round_time">{this.props.time}</span>
                <span className="heat__round_title">{this.props.title}</span>
                {heats && heats.length ? <span className="_caret" onClick={() => { this.setState({ open : !this.state.open, isNew: false }) }}>{this.state.open ? "\u25B2" : "\u25BC"}</span> : null }
            </div>
            { this.state.open ?
            <div className="heat__round-content">
                <table className="heat__round__heat-table">
                    <thead>
                        <tr>
                            <th className="heat__heat-id">heat</th>
                            <th className="heat__heat-stn">St.č.</th>
                            <th className="heat__heat-competitor">Taneční klub / škola</th>
                            <th className="heat__heat-country">Soutěžící</th>
                        </tr>
                    </thead>
                    <tbody>
                        {heats && Object.keys(heats).map((key) => <HeatHeat key={heats[key].id} bgClass={key % 2 === 0 ? 'white' : 'gray'} {...heats[key]} />)}
                    </tbody>
                </table>
            </div> : null }
        </div>
    }
}

export default HeatRound;