const fixNbsp = (string) => {
    if (!string) { return string; }

    let foundSpecialChars = string.match(/([ ]*)?[^A-Za-z0-9 ]([ ]*)?/);
    let specialCharsCounts = {};

    if (foundSpecialChars) {
        for (let i = 0; i < foundSpecialChars.length; i++) {
            let specialChar = foundSpecialChars[i];

            if (specialChar !== undefined && specialChar !== null) {
                if (specialCharsCounts[specialChar] === undefined) {
                    specialCharsCounts[specialChar] = 0;
                }

                let regex = new RegExp(`[${specialChar}]`, 'g');
                specialCharsCounts[specialChar] = string.match(regex) ? string.match(regex).length : 0;
            }
        }
    }

    let delimiter = Object.keys(specialCharsCounts).length > 0 ? Object.keys(specialCharsCounts).reduce((a, b) => specialCharsCounts[a] > specialCharsCounts[b] ? a : b) : null;

    if (delimiter !== null) {
        let parts = string.split(delimiter);

        parts = parts.map((part) => part.replace(/ /g, '\u00a0'));
        return parts.join(delimiter);
    }

    return string;
};

export default {
    fixNbsp
};