import React, {useContext} from 'react';
import { withRouter } from 'react-router-dom';

import ResultsBtn from "../../img/results_btn.svg";
import GlobalStateContext from "../../store/GlobalStateContext";

const RealtimeResults = (props) => {
    const { globalState, setGlobalState } = useContext(GlobalStateContext);
    const { data } = globalState;
    let pressTimer;

    const findStage = (_eventId) => {
        const stageInMemory = localStorage.getItem('currentStageId');

        if (null !== stageInMemory) {
            if (undefined !== data.currentEvents[_eventId].stages[stageInMemory]) {
                return stageInMemory;
            }
        }

        return Object.keys(data.currentEvents[_eventId].stages)[0];
    };

    const findStageFromEvent = (_event) => {
        const stageInMemory = localStorage.getItem('currentStageId');

        if (null !== stageInMemory) {
            if (undefined !== _event.stages[stageInMemory]) {
                return stageInMemory;
            }
        }

        return Object.keys(_event.stages)[0];
    };

    const _onClick = () => {
        const { realtime, history } = props;
        const eventInMemory = localStorage.getItem('currentEventId');


        if (undefined !== props.event) {
            const stageId = findStageFromEvent(props.event);
            setGlobalState({selectedEvent: props.event});

            if (true === realtime) {
                history.push(`/realtime-results/${props.event.uuid}/${stageId}`)
            } else {
                history.push(`/results/${props.event.uuid}`)
            }
        } else if (undefined !== props.eventId) {
            setGlobalState({selectedEvent: undefined});

            if (true === realtime) {
                const stageId = findStage(props.eventId);
                history.push(`/realtime-results/${props.eventId}/${stageId}`)
            } else {
                history.push(`/results/${props.eventId}`)
            }
        } else {
            setGlobalState({selectedEvent: undefined});
            if (Object.keys(data.currentEvents).length === 1 || null !== eventInMemory) {
                let eventId = (null !== eventInMemory && undefined !== data.currentEvents[eventInMemory]) ? eventInMemory : Object.keys(data.currentEvents)[0];
                const stageId = findStage(eventId);

                if (true === realtime) {
                    history.push(`/realtime-results/${eventId}/${stageId}`)
                } else {
                    history.push(`/results/${eventId}`)
                }
            } else {
                setGlobalState({
                    showEventSwitcher: true,
                    eventSwitcherMode : "results",
                });
            }
        }
    };

    const _onLongTouch = () => {
        if (Object.keys(globalState.data.currentEvents).length === 1) {
            _onClick();
        } else {
            setGlobalState({
                showEventSwitcher: true,
                eventSwitcherMode : "results",
            });
        }
    };

    const _onTouchStart = () => {
        pressTimer = setTimeout(_onLongTouch, 500);

        return false;
    };

    const _onTouchEnd = () => {
        if (undefined !== pressTimer) {
            clearTimeout(pressTimer);
        }

        return false;
    };




    // render
    if (props.visible) {
        return <button className={["btn btn--results", props.newResults ? 'new-results' : null].join(' ')} onClick={_onClick}  onTouchStart={_onTouchStart} onTouchEnd={_onTouchEnd} onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); e.cancelBubble = true; return false; }}>
            <img src={ResultsBtn} alt="Výsledky" />
        </button>
    }

    return null;
};

export default withRouter(RealtimeResults);