import { useState } from 'react';

const useGlobalState = (defaultState) => {
    const [globalState, setState] = useState(defaultState || {});

    const setGlobalState = (payload) => {
        return setState({...globalState, ...payload});
    };

    return {globalState, setGlobalState};
};

export default useGlobalState;