import React, {useContext, useEffect, useState} from 'react';

import Firebase from "../Utils/Firebase";
import DateFormater from "../Utils/DateFormater";

import Results from "../Components/Results";
import {default as ResultsBtn} from "../Components/Buttons/Results";
import {default as BackBtn} from "../Components/Buttons/Back";
import GlobalStateContext from "../store/GlobalStateContext";

function usePublishedResults (eventId, stageId) {
    const [results, setResults] = useState({});

    useEffect(() => {
        return Firebase.listenRealtimeResults(eventId, stageId, (data) => {
            setResults(data);
        });
    }, []);

    return results;
}

const RealtimeResults = (props) => {
    const { globalState } = useContext(GlobalStateContext);
    const currentEventId = props.match.params.eventId;
    const results = usePublishedResults(props.match.params.eventId, props.match.params.stageId);
    let currentEvent;
    if (undefined !== globalState.selectedEvent) {
        currentEvent = globalState.selectedEvent;
    } else {
        currentEvent = globalState.data.currentEvents[props.match.params.eventId];
    }
    const currentStage = currentEvent.stages[props.match.params.stageId];

    // const calendarEvent = CalendarHelper.findCalendarEvent(currentEventId);

    if ( "object" !== typeof currentEvent ) {
        props.history.goBack();

        return null;
    }

    return <div className="page--realtime-results">
        <header>
            <BackBtn />

            <span id="date">{ DateFormater.format(currentStage.date) }</span>
            <h1>{currentStage.title}</h1>
            <ResultsBtn eventId={currentEventId} visible={true} />
            {/*<h2>{ calendarEvent.subTitle }</h2>*/}
        </header>


        { Object.keys(results).length > 0 ? <Results results={results} /> : null }
    </div>;
};

export default RealtimeResults;