import React, { Component } from 'react';
import HeatDate from "./Heat/HeatDate";
import moment from "moment";

class TimeTable extends Component
{
    render() {
        const { heats } = this.props;


        return <div>
            {heats && Object.keys(heats).map((key) => <HeatDate key={key} date={moment(heats[key].date).format("LL")} rounds={heats[key].rounds} />)}
        </div>
    }
}

export default TimeTable;