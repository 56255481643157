import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import StringHelper from "../Utils/StringHelper";

const ResultsList = (props) => {
    const { results } = props;
    const { eventId } = props.match.params;
    const [sortedResults, setSortedResults] = useState([]);

    useEffect(() => {
        const _sortedResults = [];
        for (const stageId in results) {
            if (results.hasOwnProperty(stageId)) {
                const stageResults = results[stageId];

                for (const resultsId in stageResults) {
                    if (stageResults.hasOwnProperty(resultsId)) {
                        _sortedResults.push({...stageResults[resultsId], stageId: stageId});
                    }
                }
            }
        }

        if (props.isToday) {
            _sortedResults.sort((a, b) => (a.time > b.time) ? -1 : 1);
        } else {
            _sortedResults.sort((a, b) => (a.title > b.title) ? 1 : -1);
        }

        setSortedResults(_sortedResults);
    }, [props.results]);

    return <div>
        <ul className="results__list">
            {sortedResults
                ? sortedResults.map((resultRow, index) => <li key={index} className="results__list__item"><Link to={`/results/${eventId}/${resultRow.stageId}/${resultRow.id}`}>{ StringHelper.fixNbsp(resultRow.title) }</Link></li>)
                : <li className="results__list__item results__list__item--no-items">Nejsou k dispozici žádné výsledky</li>}
        </ul>
    </div>
};

export default withRouter(ResultsList);