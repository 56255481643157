import React, {useContext, useEffect, useState} from 'react';

import Item from "../Components/Calendar/Item";
import {default as BackBtn} from "../Components/Buttons/Back";
import {default as LiveContentBtn} from "../Components/Buttons/LiveContent";

import GlobalStateContext from "../store/GlobalStateContext";
import DateFormater from "../Utils/DateFormater";
import useResultsAndTimetableChecker from "../Utils/useResultsAndTimetableChecker";


const Calendar = () => {
    const {globalState} = useContext(GlobalStateContext);
    const { calendar } = globalState.data;
    const resultsAndTimetables = useResultsAndTimetableChecker(calendar);

    return <div className="page--calendar">
        <header>
            <BackBtn />

            <span id="quartal">Jaro 2024</span>
            <h1>
                Kalendář
            </h1>
            <LiveContentBtn visible={globalState.showLiveContentBtn} />
        </header>

        <ul className="calendar__list">
            { Object.keys(calendar || {}).map((key, index) => {
                const calendarEvent = calendar[key];
                const _resultsAndTimetable = calendarEvent.event ? resultsAndTimetables[calendarEvent.event.uuid] : undefined;

                return <Item
                    key={key}
                    id={calendarEvent.uuid}
                    title={`${DateFormater.format(calendarEvent.date, "D. M.")} ${calendarEvent.title}`}
                    event={calendarEvent.event}
                    timetable={ undefined !== _resultsAndTimetable && true === _resultsAndTimetable.timetable }
                    results={ undefined !== _resultsAndTimetable && true === _resultsAndTimetable.results }
                />
            }) }
        </ul>
    </div>
};

export default Calendar;