import React, {useContext, useEffect, useState} from 'react';

import Firebase from "../Utils/Firebase";
import GlobalStateContext from "../store/GlobalStateContext";
import {default as BackBtn} from "../Components/Buttons/Back";
import DateFormater from "../Utils/DateFormater";
import ResultsList from "../Components/ResultsList";

function useResultList(_event, history) {
    const [results, setResults] = useState({});

    if ( "object" !== typeof _event ) {
        history.goBack();

        return null;
    }

    useEffect(() => {
        return Firebase.listenResults(_event, (data) => {
            setResults({...data});
        });
    }, [_event]);

    return results;
}

const Results = (props) => {
    const { eventId } = props.match.params;
    const { globalState } = useContext(GlobalStateContext);
    let currentEvent;
    if (undefined !== globalState.selectedEvent) {
        currentEvent = globalState.selectedEvent;
    } else {
        currentEvent = globalState.data.currentEvents[props.match.params.eventId];
    }
    // const calendarEvent = CalendarHelper.findCalendarEvent(eventId);

    const results = useResultList(currentEvent, props.history);

    return <div className="page--results">
         <header>
             <BackBtn />

             <span id="date">{ DateFormater.format(currentEvent.dateStart) }</span>
             <h1>{currentEvent.title}</h1>
             {/*<h2>{ calendarEvent.subTitle }</h2>*/}
         </header>


         { Object.keys(results).length > 0 ? <ResultsList results={results} isToday={globalState.data.currentEvents.hasOwnProperty(eventId)} /> : null }
     </div>;
};

export default Results;