import React, {useContext} from 'react';
import { withRouter } from 'react-router-dom';

import GlobalStateContext from "./store/GlobalStateContext";

import EventSwitcherItem from "./EventSwitcherItem";

const EventSwitcher = (props) => {
    const {globalState, setGlobalState} = useContext(GlobalStateContext);
    const { currentEvents } = globalState.data;

    const _hideEventSwitcher = () => {
        setGlobalState({
            showEventSwitcher : false,
            eventSwitcherMode : null,
        });
    };

    const _onEventSelect = (id) => {
        setGlobalState({
            showEventSwitcher : false,
            eventSwitcherMode : null,
        });

        localStorage.setItem('currentEventId', id);
        localStorage.removeItem('currentStageId');

        switch (globalState.eventSwitcherMode) {
            case 'results':
                props.history.push(`/realtime-results/${id}/${Object.keys(currentEvents[id].stages)[0]}`);
                break;

            default:
                props.history.push(`/livecontent/${id}/${Object.keys(currentEvents[id].stages)[0]}`);
        }


    };

    return <div className='modal' onClick={_hideEventSwitcher}>
        <div className="modal-dialog">
            <ul className="eventSwitcherList">
                {Object.keys(currentEvents || {}).map((key) => {
                    const event = currentEvents[key];

                    return <EventSwitcherItem key={key} id={event.uuid} title={event.title} onClick={_onEventSelect} />
                })}
            </ul>
        </div>
    </div>;
};

export default withRouter(EventSwitcher);