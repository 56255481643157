import React, {useContext} from 'react';
import {withRouter} from "react-router-dom";

import StageSwitcherItem from "./StageSwitcherItem";
import GlobalStateContext from "./store/GlobalStateContext";

const StageSwitcher = (props) => {
    const {globalState, setGlobalState} = useContext(GlobalStateContext);
    const currentEventId = props.eventId;
    const currentEvent = globalState.data.currentEvents[currentEventId];
    const currentStageId = localStorage.getItem('currentStageId') || Object.keys(currentEvent.stages)[0];

    const _hideStageSwitcher = () => {
        setGlobalState({showStageSwitcher : false});
    };

    const _onStageSelect = (id) => {
        setGlobalState({
            showStageSwitcher : false,
            currentStageId : id,
        });

        localStorage.setItem('currentStageId', id);
        props.history.replace(`/livecontent/${currentEventId}/${id}`);
    };

    return <div className='modal' onClick={_hideStageSwitcher}>
        <div className="modal-dialog">
            <ul className="stageSwitcherList">
                {Object.keys(currentEvent.stages).map((key) => {
                    const stage = currentEvent.stages[key];

                    return <StageSwitcherItem key={key} id={key} title={stage.title} checked={key === currentStageId} onStageClick={_onStageSelect} />
                })}
            </ul>
        </div>
    </div>;
};

export default withRouter(StageSwitcher);