import React from 'react';
import { withRouter } from 'react-router-dom';
import BackBtn from "../../img/left.svg";

const Back = (props) => {
    const onClick = () => {
        props.history.goBack();
        document.documentElement.scrollTop = 0;

        return false;
    };

    return <button className="btn btn--back" onClick={onClick}>
        <img src={BackBtn} alt="Zpět" />
    </button>
};

export default withRouter(Back);