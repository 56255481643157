import * as firebase from "firebase";
import 'firebase/database';
import Timetable from "./Firebase/Timetable";


firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
});

const firebaseDb = firebase.database();
const activeRefs = {
    livecontent : {
        data : null,
        visibility : null,
    },
    liveContentAvailability : [],
    realtimeResultsAvailability : [],
};

const listenLiveContent = (_eventId, _stageId, _callback) => {
    if (null !== activeRefs.livecontent.data) {
        activeRefs.livecontent.data.off();
    }

    if (null !== activeRefs.livecontent.visibility) {
        activeRefs.livecontent.visibility.off();
    }

    let _data = {};
    let _visibility = {};

    activeRefs.livecontent.data = firebaseDb.ref(`panel/${_eventId}/${_stageId}/data`);
    activeRefs.livecontent.data.on('value', (snapshot) => {
        _data = snapshot.val() || {};

        if (typeof _callback === "function") {
            _callback(_data, _visibility);
        }
    });

    activeRefs.livecontent.visibility = firebaseDb.ref(`panel/${_eventId}/${_stageId}/visibility`);
    activeRefs.livecontent.visibility.on('value', (snapshot) => {
        _visibility = snapshot.val() || {};

        if (typeof _callback === "function") {
            _callback(_data, _visibility);
        }
    });
};

const CalendarCheckResultsAndTimetable = (_events) => {
    return new Promise((resolve, reject) => {
        if ("object" === typeof _events) {
            const resolved = [];
            const returns = {};
            const paths = {};

            Object.keys(_events).forEach((eventId) => {
                const event = _events[eventId];

                if ("object" === typeof event.stages) {
                    Object.keys(event.stages).forEach((stageId) => {
                        paths[`${eventId}/${stageId}`] = {
                            eventId: eventId,
                            stageId: stageId,
                        };
                    });
                }
            });


            Object.keys(paths).forEach((path) => {
                const pathObject = paths[path];

                firebaseDb.ref(`results/${path}/published_app`).once('value').then((snapshot) => {
                    if (undefined === returns[pathObject.eventId]) {
                        returns[pathObject.eventId] = {};
                    }

                    returns[pathObject.eventId].results = returns[pathObject.eventId].results || null !== snapshot.val();
                    resolved.push(path + '_results');

                    if (resolved.length === Object.keys(paths).length * 2) {
                        resolve(returns);
                    }
                });

                firebaseDb.ref(`timetable/${path}`).once('value').then((snapshot) => {
                    if (undefined === returns[pathObject.eventId]) {
                        returns[pathObject.eventId] = {};
                    }

                    returns[pathObject.eventId].timetable = returns[pathObject.eventId].timetable || null !== snapshot.val();
                    resolved.push(path + '_timetable');

                    if (resolved.length === Object.keys(paths).length * 2) {
                        resolve(returns);
                    }
                });
            });
        } else {
            reject('Object not provided');
        }
    });
};

const listenResults = (_event, callback) => {
    const paths = {};
    const refs = [];
    let results = {};


    if ("object" === typeof _event.stages) {
        Object.keys(_event.stages).forEach((stageId) => {
            paths[`${_event.uuid}/${stageId}`] = {
                eventId: _event.uuid,
                stageId: stageId,
            };
        });
    }

    Object.keys(paths).forEach((path) => {
        const pathObject = paths[path];
        results[pathObject.stageId] = {};

        const ref = firebaseDb.ref(`results/${path}/public`);
        refs.push(ref);

        ref.once('value', (snapshot) => {
            if (null !== snapshot.val()) {
                results[pathObject.stageId] = snapshot.val();
                listedChanges();

                if ("function" === typeof callback) {
                    callback(results);
                }
            }
        });

        const listedChanges = () => {
            ref.on('child_added', (snapshot) => {
                if (undefined === results[pathObject.stageId][snapshot.key]) {
                    results[pathObject.stageId][snapshot.key] = snapshot.val();

                    if ("function" === typeof callback) {
                        callback(results);
                    }
                }
            });

            ref.on('child_changed', (snapshot) => {
                results[pathObject.stageId][snapshot.key] = snapshot.val();

                if ("function" === typeof callback) {
                    callback(results);
                }
            });

            ref.on('child_removed', (snapshot) => {
                results[pathObject.stageId][snapshot.key] = undefined;

                if ("function" === typeof callback) {
                    callback(results);
                }
            });
        };
    });

    return () => {
        refs.forEach((ref) => {
            ref.off();
        });
    }
};

const fetchResults = (_eventId, _stageId, _roundId) => {
    return new Promise((resolve, reject) => {
        firebaseDb.ref(`results/${_eventId}/${_stageId}/public/${_roundId}`).once('value').then((snapshot) => {
            const firebaseData = snapshot.val();

            if (null !== firebaseData) {
                resolve(firebaseData);
            } else {
                reject();
            }
        });
    });
};

const listenRealtimeResults = (_eventId, _stageId, callback) => {
    const ref = firebaseDb.ref(`results/${_eventId}/${_stageId}/published_app`);

    ref.on('value', (snapshot) => {
        const resultId = snapshot.val();

        if (null !== resultId) {
            firebaseDb.ref(`results/${_eventId}/${_stageId}/public/${resultId}`).once('value')
                .then((snapshot) => {
                    if (null !== snapshot.val()) {
                        callback(snapshot.val());
                    }
                });
        }
    });

    return () => {
        ref.off();
    }
};

const isLiveContentAvailable = (eventId, stageId, callback) => {
    const ref = firebaseDb.ref(`panel/${eventId}/${stageId}/data`);

    ref.once('value').then((snapshot) => {
        if (null !== snapshot.val()) {
            callback(true);
        } else {
            activeRefs.liveContentAvailability.push(ref);
            ref.on('value', (snapshot) => {
                if ( null !== snapshot.val() ) {
                    callback(true);
                }
            });
        }
    });
};

const stopCheckingLiveContentAvailability = () => {
    activeRefs.liveContentAvailability.forEach((ref, key) => {
        if (undefined !== ref) {
            ref.off();
            activeRefs.liveContentAvailability[key] = undefined;
        }
    });
};

const isRealtimeResultsAvailable = (eventId, stageId, callback) => {
    const ref = firebaseDb.ref(`results/${eventId}/${stageId}/published_app`);

    ref.once('value').then((snapshot) => {
        if (null !== snapshot.val()) {
            callback(true);
        } else {
            activeRefs.realtimeResultsAvailability.push(ref);
            ref.on('value', (snapshot) => {
                if ( null !== snapshot.val() ) {
                    callback(true);
                }
            });
        }
    });
};

const stopCheckingRealtimeResultsAvailability = () => {
    activeRefs.realtimeResultsAvailability.forEach((ref, key) => {
        if (undefined !== ref) {
            ref.off();
            activeRefs.realtimeResultsAvailability[key] = undefined;
        }
    });
};

export default {
    listenLiveContent,
    CalendarCheckResultsAndTimetable,
    listenResults,
    listenRealtimeResults,
    fetchResults,
    isLiveContentAvailable,
    stopCheckingLiveContentAvailability,
    isRealtimeResultsAvailable,
    stopCheckingRealtimeResultsAvailability,
    Timetable: new Timetable(firebaseDb),
    firebase,
}