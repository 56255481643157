import React from 'react';
import { withRouter } from 'react-router-dom';
import ResultsBtn from "../../img/results_archive_btn.svg";

const Results = (props) => {
    const _onClick = () => {
        const {onClick, history} = props;

        if ("function" === typeof onClick) {
            onClick();
        }

        history.push(`/results/${props.eventId}`);
    };

    if (props.visible) {
        return <button className={"btn btn--results"} onClick={_onClick}>
            <img src={ResultsBtn} alt="Archiv výsledků" />
        </button>
    }

    return null;
};

export default withRouter(Results);