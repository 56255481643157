import React, {useContext} from 'react';
import StageSwitcherBtn from "../../img/stage-switch.svg";
import GlobalStateContext from "../../store/GlobalStateContext";

const StageSwitcher = () => {
    const {setGlobalState} = useContext(GlobalStateContext);

    return <button className="btn btn--stage-switcher" onClick={() => { setGlobalState({ showStageSwitcher : true }); }}>
        <img src={StageSwitcherBtn} alt="Výběr stage" />
    </button>;
};

export default StageSwitcher;