export default class Timetable {
    constructor(firebaseDb) {
        this.firebaseDb = firebaseDb;
        this.activeRefs = [];
    }

    isAvailable(eventId, stageId, callback) {
        const ref = this.firebaseDb.ref(`timetable/${eventId}/${stageId}`);

        ref.once('value').then((snapshot) => {
            if (null !== snapshot.val()) {
                callback(true);
            } else {
                this.activeRefs.push(ref);
                ref.on('value', (snapshot) => {
                    if (null !== snapshot.val()) {
                        callback(true);
                    } else {
                        callback(false);
                    }
                });
            }
        });
    }

    stopCheckingAvailability() {
        this.activeRefs.forEach((ref, key) => {
            if (undefined !== ref) {
                ref.off();
                this.activeRefs[key] = undefined;
            }
        });
    }

    listen(_eventId, _stageId, callback) {
        const ref = this.firebaseDb.ref(`timetable/${_eventId}/${_stageId}`);

        ref.on('value', (snapshot) => {
            let value = snapshot.val();
            if (null !== value) {
                if (value.data !== undefined && value.data !== null) {
                    callback(value.data);
                }
            }
        });

        return () => {
            ref.off();
        }
    }

    async fetchCurrentTimetableHeat(_eventId, _stageId) {
        const snapshot = await this.firebaseDb.ref(`timetable/${_eventId}/${_stageId}`).once('value');
        const firebaseData = snapshot.val();

        if (undefined !== firebaseData.selected) {
            if (undefined !== firebaseData.selected.heat) {
                const selected = firebaseData.selected.heat;
                const dateData = firebaseData.data[selected.date];
                const roundData = dateData.rounds[selected.round];
                const heatData = roundData.heats[selected.key];

                return {
                    title: roundData.discipline,
                    heat: heatData,
                    heat_nr: `${heatData.id}/${roundData.heats.length}`,
                };
            }
        }

        return null;
    }
}