import React, {useContext, useEffect, useState} from 'react';

import 'firebase/database';
import CDMLogo from "../img/cdm_logo.png";

import {default as BackBtn} from "../Components/Buttons/Back";
import {default as StageSwitcherBtn} from "../Components/Buttons/StageSwitcher";
import {default as TimeTableBtn} from "../Components/Buttons/TimeTable";
import {default as RealtimeResultsBtn} from "../Components/Buttons/RealtimeResults";

import GlobalStateContext from "../store/GlobalStateContext";
import StageSwitcher from "../StageSwitcher";
import Firebase from "../Utils/Firebase";
import DateFormater from "../Utils/DateFormater";
import HeatHeat from "../Components/Heat/HeatHeat";

function useVariables(eventId, stageId) {
    const [variables, setVariables] = useState({});

    useEffect(() => {
        return Firebase.listenLiveContent(eventId, stageId, (data, visibility) => {
            let _variables = {...variables};

            Object.keys(_variables).forEach((key) => {
                _variables[key].value = null;
            });

            Object.keys(data).forEach((key) => {
                if (undefined === _variables[key]) {
                    _variables[key] = {};
                }

                _variables[key].value = data[key];
            });

            Object.keys(visibility).forEach((key) => {
                if (undefined === _variables[key]) {
                    _variables[key] = {};
                }

                _variables[key].visibility = visibility[key];
            });

            setVariables({...variables, ..._variables});
        });
    }, [stageId]);

    return variables;
}


const LiveContent = (props) => {
    const {globalState} = useContext(GlobalStateContext);
    const { data } = globalState;
    const currentEventId = props.match.params.eventId;
    const currentStageId = props.match.params.stageId;
    const [screen, setScreen] = useState({
        default : true,
        multiple : false,
        disciplinaRound : false,
        heat : false
    });
    const [heatData, setHeatData] = useState(null);

    const [showStageSwitcherBtn, setShowStageSwitcherBtn] = useState(false);
    const variables = useVariables(currentEventId, currentStageId);

    useEffect(() => {
        if (Object.keys(data.currentEvents[currentEventId].stages).length > 1) {
            setShowStageSwitcherBtn(true);
        }
    }, []);

    useEffect(() => {
        if (undefined !== variables['screen^System'] && "heat" === variables['screen^System'].value) {
            setScreen({...screen,
                default : false,
                multiple : false,
                heat: true
            });
        } else if (undefined !== variables['startovky^Text'] && "string" === typeof variables['startovky^Text'].value) {
            setScreen({...screen,
                default : false,
                multiple : true,
                heat: false
            });
        } else {
            setScreen({...screen,
                default : true,
                multiple : false,
                heat: false
            });
        }
    }, [variables]);

    /*constructor(props) {
        super(props);

        // this.lastResultsId = null;
        // this.lastTimeTableId = null;
        //
        // this.firebaseResultsRef = null;
        // this.firebaseTimetableRef = null;
        //
        // if (this.data.results !== undefined && this.data.results !== null && this.data.results !== false) {
        //     this.lastReadedResultsId = localStorage.getItem(this.data.results.firebase_id + '/lastResultsId') || null;
        // } else {
        //     this.lastReadedResultsId = null;
        // }
        //
        // if (this.data.timetable !== undefined && this.data.timetable !== null && this.data.timetable !== false) {
        //     this.lastReadedTimeTableId = localStorage.getItem(this.data.timetable.firebase_id + '/lastTimeTableId') || null;
        //     this.lastReadedTimeTableId = this.lastReadedTimeTableId !== null ? parseInt(this.lastReadedTimeTableId) : null;
        // } else {
        //     this.lastReadedTimeTableId = null;
        // }
        //
        // this.state = {
        //     hasNewResults : false,
        //     newTimeTables : false,
        //
        //
        //     // Buttons
        //     resultsBtn : false,
        //     timeTableBtn : false
        // };
        //
        // this.firebaseOnValueResultsNode = this.firebaseOnValueResultsNode.bind(this);
        // this.firebaseOnValueTimeTableNode = this.firebaseOnValueTimeTableNode.bind(this);
        // this.markNewResultsReaded = this.markNewResultsReaded.bind(this);
        // this.markNewTimeTableReaded = this.markNewTimeTableReaded.bind(this);
    }*/

    /*componentDidMount() {

        // start listen firebase
        let database = firebase.database();

        if (this.data.results !== undefined && this.data.results !== null && this.data.results !== false) {
            this.firebaseResultsRef = database.ref('results/' + this.data.results.firebase_id);
            this.firebaseResultsRef.on('value', this.firebaseOnValueResultsNode);
        }

        if (this.data.timetable !== undefined && this.data.timetable !== null && this.data.timetable !== false) {
            this.firebaseTimetableRef = database.ref('timetable/' + this.data.timetable.firebase_id);
            this.firebaseTimetableRef.on('value', this.firebaseOnValueTimeTableNode);
        }
    }

    firebaseOnValueResultsNode(snapshot) {
        let value = snapshot.val();

        if (value === null || value.data === undefined || value.published__app === undefined) {
            return;
        }

        this.setState({
            resultsBtn : !(Object.keys(value.data).length === 0
                || value.published__app === undefined
                || value.published__app === null)
        });

        if ( value.published__app !== this.lastResultsId ) {
            this.lastResultsId = value.published__app;

            if (this.lastResultsId !== this.lastReadedResultsId && this.data.results.realtime) {
                this.setState({
                    hasNewResults: true
                });
            }
        }
    }

    firebaseOnValueTimeTableNode(snapshot) {
        let value = snapshot.val();

        if (value === null || value.data === undefined) {
            return;
        }

        this.setState({
            timeTableBtn : !(Object.keys(value.data).length === 0)
        });

        if ( value.lastRoundId !== this.lastTimeTableId ) {
            this.lastTimeTableId = value.lastRoundId;

            if (this.lastTimeTableId !== this.lastReadedTimeTableId && this.data.timetable.realtime) {
                this.setState({
                    newTimeTables: true
                });
            }
        }
    }

    markNewResultsReaded() {
        localStorage.setItem(this.data.results.firebase_id + '/lastResultsId', this.lastResultsId);
        this.lastReadedResultsId = this.lastResultsId;

        this.setState({
            hasNewResults: false
        });
    }

    markNewTimeTableReaded() {
        localStorage.setItem(this.data.results.firebase_id + '/lastTimeTableId', this.lastTimeTableId);
        this.lastReadedTimeTableId = this.lastTimeTableId;

        this.setState({
            hasNewResults: false
        });
    }
    */

    const canRenderVariable = (_name) => {
        const variable = variables[_name.replace('.', '^')];

        if (undefined !== variable) {
            if (false !== variable.visibility) {
                if (undefined !== variable.value && null !== variable.value) {
                    if (typeof variable.value === 'string' && variable.value.trim().length > 0 && variable.value !== " / ") {
                        return true;
                    }

                    return typeof variable.value !== 'string';
                }
            }
        }

        return false;
    };

    const renderDefaultScreen = () => {
        return <div className="variable-container" id="screen_default">
            { true === canRenderVariable("startovka.Text") ? <div key="startovka.Text" className="text-center"><div className="variable" id="startovka.Text">{variables['startovka^Text'].value || ""}</div></div> : null }
            { true === canRenderVariable("choreografie.Text") ? <div key="choreografie.Text" className="variable" id="choreografie.Text" >{variables['choreografie^Text'].value || ""}</div> : null }
            { true === canRenderVariable("skola.Text") ? <div key="skola.Text" className="variable" id="skola.Text">{variables['skola^Text'].value || ""}</div> : null }
            { true === canRenderVariable("disciplina.Text") ? <div key="disciplina.Text" className="variable" id="disciplina.Text">{variables['disciplina^Text'].value || ""}</div> : null }
            { true === canRenderVariable("round.Text") ? <div key="round.Text" className="variable" id="round.Text">{variables['round^Text'].value || ""}</div> : null }
            { true === canRenderVariable("heat.Text") ? <div key="heat.Text" className="text-center"><div className="variable" id="heat.Text">Heat: {variables['heat^Text'].value || ""}</div></div> : null }
        </div>
    };

    const renderMultipleScreen = () => {
        return <div className="variable-container" id="screen_default">
            { true === canRenderVariable("startovky.Text") ? <div key="startovky.Text" className="text-center"><div className="variable" id="startovky.Text">{variables['startovky^Text'].value || ""}</div></div> : null }
            { true === canRenderVariable("disciplina.Text") ? <div key="disciplina.Text_2" className="variable" id="disciplina.Text_2">{variables['disciplina^Text'].value || ""}</div> : null }
        </div>
    };

    const renderDisciplinaRoundScreen = () => {
        return <div className="variable-container" id="screen_default">
            { true === canRenderVariable("disciplina.Text") ? <div key="disciplina.Text" className="variable" id="disciplina.Text">{variables['disciplina^Text'].value || ""}</div> : null }
            { true === canRenderVariable("round.Text") ? <div key="round.Text" className="variable" id="round.Text">{variables['round^Text'].value || ""}</div> : null }
        </div>
    };

    const renderHeatScreen = () => {
        Firebase.Timetable.fetchCurrentTimetableHeat(currentEventId, currentStageId).then((data) => {
            setHeatData(data);
        });

        if (null !== heatData) {
            return <div className="livecontent-heat-container" id="screen_heat">
                <div key="disciplina.Text" className="variable" id="disciplina.Text">{ heatData.title || ""}</div>
                <div key="heat_nr" className="variable" id="heat_nr">{ heatData.heat_nr || ""}</div>
                <table className="heat__round__heat-table">
                    <thead>
                    <tr>
                        <th className="heat__heat-stn">St. č.</th>
                        <th className="heat__heat-competitor" colSpan={2}>Soutěžící</th>
                    </tr>
                    </thead>
                    <tbody>
                    <HeatHeat bgClass={'white'} {...heatData.heat} hideHeatNr={true} />
                    </tbody>
                </table>
            </div>
        }

        return null;
    }

    const renderScreen = () => {
        if ( true === screen.multiple ) {
            return renderMultipleScreen();
        } else if ( true === screen.disciplinaRound ) {
            return renderDisciplinaRoundScreen();
        } else if (true === screen.heat) {
            return renderHeatScreen();
        }

        return renderDefaultScreen();
    };

    const currentEvent = data.currentEvents[currentEventId];
    const currentStage = null !== currentStageId ? currentEvent.stages[currentStageId] : {};

    return <div className="page--livecontent">
        <BackBtn />
        <img src={CDMLogo} alt="Czech Dance Masters - insportline" id="cdm_logo" />

        <div className="btn-group--vertical">
            { true === showStageSwitcherBtn ? <StageSwitcherBtn /> : <span className="btn-group-spacer" /> }
            <TimeTableBtn eventId={currentEventId} visible={globalState.showTimetableBtn} />
            <RealtimeResultsBtn eventId={currentEventId} realtime={true} visible={globalState.showResultsBtn} />
        </div>

        {currentStage ? [
            <h1 key="4f89sdfsd">
                {currentEvent.title}
                <span id="date">{DateFormater.format(currentStage.date)}</span>
            </h1>,
            Object.keys(currentEvent.stages).length > 1 ? <h2 key="f89sd7f9s" className="livecontent-stage">{currentStage.title}</h2> : null
        ] : null}


        <div className="container--livecontent">
            { renderScreen() }
        </div>

        { true === globalState.showStageSwitcher ? <StageSwitcher eventId={currentEventId} /> : null }
    </div>;
};

export default LiveContent;