import React, {useContext} from 'react';
import { withRouter } from 'react-router-dom';
import TimeTableBtn from "../../img/timetable_btn.svg";

import GlobalStateContext from "../../store/GlobalStateContext";

const TimeTable = (props) => {
    const { globalState, setGlobalState } = useContext(GlobalStateContext);
    const { data } = globalState;
    let pressTimer;

    const findStage = (_eventId) => {
        const stageInMemory = localStorage.getItem('currentStageId');

        if (null !== stageInMemory) {
            if (undefined !== data.currentEvents[_eventId].stages[stageInMemory]) {
                return stageInMemory;
            }
        }

        return Object.keys(data.currentEvents[_eventId].stages)[0];
    };

    const findStageFromEvent = (_event) => {
        const stageInMemory = localStorage.getItem('currentStageId');

        if (null !== stageInMemory) {
            if (undefined !== _event.stages[stageInMemory]) {
                return stageInMemory;
            }
        }

        return Object.keys(_event.stages)[0];
    };

    const _onClick = () => {
        const eventInMemory = localStorage.getItem('currentEventId');
        const stageInMemory = localStorage.getItem('currentStageId');


        if (undefined !== props.event) {
            const stageId = findStageFromEvent(props.event);
            setGlobalState({selectedEvent: props.event});
            props.history.push(`/timetable/${props.event.uuid}/${stageId}`);
        } else if (undefined !== props.eventId) {
            const stageId = findStage(props.eventId);
            setGlobalState({selectedEvent: undefined});
            props.history.push(`/timetable/${props.eventId}/${stageId}`);
        } else {
            setGlobalState({selectedEvent: undefined});
            if (Object.keys(data.currentEvents).length === 1 || null !== eventInMemory) {
                let eventId = (null !== eventInMemory && undefined !== data.currentEvents[eventInMemory]) ? eventInMemory : Object.keys(data.currentEvents)[0];
                let stageId = null;

                if (null === stageInMemory) {
                    stageId = Object.keys(data.currentEvents[eventId].stages)[0];
                } else {
                    if (undefined !== data.currentEvents[eventId].stages[stageInMemory]) {
                        stageId = stageInMemory;
                    }
                }

                props.history.push(`/timetable/${eventId}/${stageId}`);
            } else {
                setGlobalState({
                    showEventSwitcher: true,
                    eventSwitcherMode: "timetable",
                });
            }
        }
    };

    const _onLongTouch = () => {
        if (Object.keys(globalState.data.currentEvents).length === 1) {
            _onClick();
        } else {
            setGlobalState({
                showEventSwitcher: true,
                eventSwitcherMode : "timetable",
            });
        }
    };

    const _onTouchStart = () => {
        pressTimer = setTimeout(_onLongTouch, 500);

        return false;
    };

    const _onTouchEnd = () => {
        if (undefined !== pressTimer) {
            clearTimeout(pressTimer);
        }

        return false;
    };

    if (true === props.visible) {
        return <button className="btn btn--timetable" onClick={_onClick} onTouchStart={_onTouchStart} onTouchEnd={_onTouchEnd} onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); e.cancelBubble = true; return false; }}>
            <img src={TimeTableBtn} alt="Harmonogram" />
        </button>
    } else {
        return <span className="btn-group-spacer" />
    }
};

export default withRouter(TimeTable);