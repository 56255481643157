import * as Sentry from '@sentry/browser';

const fetchAppData = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/app/cdm/fetch-data`);
        return response.json();
    } catch (e) {
        Sentry.captureException(e);

        return {};
    }
};

export default {
    fetchAppData
};