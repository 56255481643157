import React, {useEffect, useState} from 'react';
import Firebase from "./Firebase";

export default function useResultsAndTimetableChecker(calendarEvents)
{
    const [state, setState] = useState({});

    useEffect(() => {
        const events = {};
        Object.keys(calendarEvents).forEach((key) => {
            const event = calendarEvents[key].event;

            if (null !== event) {
                events[event.uuid] = event;
            }
        });

        Firebase.CalendarCheckResultsAndTimetable(events)
            .then((data) => {
                setState(data);
            });

    }, []);

    return state;
}