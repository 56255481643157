import React  from 'react';

import ResultsTableClassic from "./Results/ResultsTableClassic";
import ResultsTableFinal from "./Results/ResultsTableFinal";
import ResultsTableMedal from "./Results/ResultsTableMedal";

const Results = (props) => {
    const {results} = props;

    const getTable = () => {
        switch(results.type) {
            case 'classic' : return <ResultsTableClassic results={results.results} />;
            case 'final' : return <ResultsTableFinal results={results.results} />;
            case 'medals' : return <ResultsTableMedal results={results.results} />;
            default : return null;
        }
    };

    return <div className="results-container">
        <div className="header-texts">
            <div className="round-title">{ results ? results.title : '' }</div>
        </div>
        {getTable()}
    </div>
};

export default Results;