import React, {useContext} from 'react';

import CDMLogo from '../img/cdm_logo.png';

import {default as TimeTableBtn} from "../Components/Buttons/TimeTable";
import {default as RealtimeResultsBtn} from "../Components/Buttons/RealtimeResults";
import {default as LiveContentBtn} from "../Components/Buttons/LiveContent";

import GlobalStateContext from "../store/GlobalStateContext";

const Menu = (props) => {
    const { globalState } = useContext(GlobalStateContext);

    return <div className="page--menu">
        <img src={CDMLogo} alt="Czech Dance Masters - insportline" id="cdm_logo" />

        <div className="btn-group--vertical">
            <LiveContentBtn visible={globalState.showLiveContentBtn} />
            <TimeTableBtn visible={globalState.showTimetableBtn} />
            <RealtimeResultsBtn realtime={true} visible={globalState.showResultsBtn} />
        </div>

        <ul className="menu">
            <li className="menu__item"><button onClick={() => { props.history.push('/calendar'); }}>Kalendář</button></li>
            {/*<li className="menu__item"><button>Novinky</button></li>*/}
            <li className="menu__item"><a href="https://www.instagram.com/czech_dance_masters/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            <li className="menu__item"><a href="https://www.facebook.com/czechdancemasters/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li className="menu__item"><a href="https://crproduction.eu/" target="_blank" rel="noopener noreferrer">Videa</a></li>
        </ul>
    </div>
};

export default Menu;